import {
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MenuStyled, SidebarStyle } from "./Sidebar.style";
import { SidebarList } from "./SideItems";
import { IPropsT, ISideItemsChildrenT, ISideItemsT } from "./Sidebar.types";
import "react-pro-sidebar/dist/css/styles.css";
import { isSubMenuActive } from "./utils/isSubMenuActive/isSubMenuActive";
import { isActive } from "./utils/isActive";
import { RoleManager } from "../../../services";
import Logo from "../../../assets/Logo.png";
import LogoutIcon from "./assets/LogoutIcon";
import { useContext } from "react";
import { CommonContext } from "../../../context";

const Sidebar = ({ collapse, setCollapse }: IPropsT) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [hasAccess] = RoleManager();
  const {
    actions: { logOut },
  } = useContext(CommonContext);

  return (
    <SidebarStyle>
      <ProSidebar collapsed={collapse} breakPoint="md">
        <SidebarHeader>
          <div className="sidebar-header">
            <div className="d-flex align-items-center justify-content-between">
              {!collapse && <img src={Logo} alt="logo" />}
            </div>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <MenuStyled iconShape="circle">
            {SidebarList.map((item: ISideItemsT) => {
              return item.children
                ? (item?.isShow ? item?.isShow : true) &&
                    !item?.isNotAdmin &&
                    item?.children?.find((ch: any) => !hasAccess(ch.id)) && (
                      <SubMenu
                        key={item.id}
                        title={t([`SIDEBAR.${item.title}`])}
                        icon={item.icon}
                        className={`  ${
                          isSubMenuActive(
                            item.children,
                            location.pathname,
                            collapse
                          )
                            ? "active"
                            : ""
                        } `}
                      >
                        {item.children.map((children: ISideItemsChildrenT) => {
                          if (!hasAccess(children.id) && !children.isNotAdmin) {
                            return (
                              <MenuItem
                                key={children.id}
                                className={`  ${
                                  isActive(
                                    children.insidePaths,
                                    location.pathname
                                  )
                                    ? "active"
                                    : ""
                                } `}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (location.pathname !== children.path) {
                                    navigate(children.path);
                                  }
                                }}
                              >
                                {t([`SIDEBAR.${children.title}`])}
                              </MenuItem>
                            );
                          }
                        })}
                      </SubMenu>
                    )
                : hasAccess(item.id) && (
                    <MenuItem
                      key={item.id}
                      className={` ${
                        isActive(item.insidePaths, location.pathname)
                          ? "active"
                          : ""
                      }`}
                      icon={item.icon}
                      onClick={(e) => {
                        e.preventDefault();
                        if (location.pathname !== item.path) {
                          navigate(item.path);
                        }
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  );
            })}
          </MenuStyled>
        </SidebarContent>
        <SidebarFooter className="sidebar-footer">
          <button className="logout" onClick={logOut}>
            Chiqish <LogoutIcon />
          </button>
        </SidebarFooter>
      </ProSidebar>
    </SidebarStyle>
  );
};

export default Sidebar;

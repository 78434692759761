import styled from "@emotion/styled";

export const MaskInputStyled = styled.div`
  .reactInputMask {
    border-radius: 12px;
    border: 1px solid transparent;
    background-color: #f5f5f5;
    color: #232323;
    box-sizing: border-box;
    width: 100%;
    padding: 14px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    transition: all 300ms ease-out;
    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
    &.error {
      border-color: red !important;
    }
  }
`;

import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { IFilterSelect } from "./FilterSelect.types";
import { FilterSelectStyled } from "./FilterSelect.style";
import { SearchParams } from "../../../services/urlSetParams/urlSetParams";
import { useNavigate } from "react-router-dom";
import useOutsideHandler from "../../../hooks/outsideHandler/outsideHandler";
import { debounce } from "../../../services";
import SelectArrow from "../../../assets/SelectArrow";
import CheckedIcon from "../../../assets/CheckedIcon";
import { useRequest } from "../../../hooks";
import get from "lodash.get";

const FilterSelect = ({
  optionAllView = true,
  searchable = false,
  label,
  children,
  className,
  name = "name",
  style = null,
  value,
  disabled = false,
  dataUrl = "",
  optional,
  field = "name",
  setFilter,
  filter,
  id = "id",
  staticOptions = [],
  onChange = undefined,
  defaultOptions = undefined,
  isClearable = true,
  method = "get",
}: IFilterSelect) => {
  const [, getParams] = SearchParams();
  const [isOpen, setIsOpen] = useState<any>(false);
  const [selectedOption, setSelectedOption] = useState<any>(undefined);
  const [optionsSelect, setOptionsSelect] = useState<any>([]);
  const outsideOfSelectRef = useRef<any>();
  const navigate = useNavigate();
  const [client, data, status, error] = useRequest();

  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  useOutsideHandler(outsideOfSelectRef, setIsOpen);

  const handleChange = (item: any) => {
    setSelectedOption(item);
    setIsOpen(false);
    // if (path) {
    //   browserStorage.set(history.location.pathname + name, {
    //     [name]: {
    //       [name]: item[field] + " " + (item.lastName ? item.lastName : ""),
    //       [id]: item._id,
    //     },
    //   });
    // }
    setFilter((prev: any) => {
      return {
        ...prev,
        [name]: get(item, field) + " " + (item.lastName ? item.lastName : ""),
        [id]: item._id,
      };
    });
    if (onChange) {
      onChange(item);
    }
  };

  const renderChildren = () => {
    return React.Children.map(children, (child) => {
      return React.cloneElement(child, {
        onClick: () => handleChange(child.props.value),
        className: `list-item ${
          child.props.value === selectedOption?.value ? "active" : ""
        }`,
      });
    });
  };

  useEffect(() => {
    const childArray = React.Children.toArray(children);
    for (const child of childArray) {
      // @ts-ignore
      if ("props" in child && child.props.value === value) {
        return setSelectedOption({
          value: child.props.value,
          name: child.props.children,
          // params: child.props.params
        });
      }
    }
  }, [value]);

  const handleSearch = async (event: any) => {
    let data: any;
    if (event) {
      data = {
        limit: 5,
        page: 1,
        search: event.target.value,
        ...optional,
      };
    } else {
      data = {
        limit: 5,
        page: 1,
        search: "",
        ...optional,
      };
    }
    if (dataUrl && searchable) {
      try {
        const res = await client.post(dataUrl, data);
        if (res.data?.data) {
          setOptionsSelect(res.data.data);
        }
      } catch (e: any) {
        if (e?.response?.data?.code === 401) {
          localStorage.clear();
          navigate("/login");
        }
      }
    }
  };

  const debounceSearch = useCallback(debounce(handleSearch, 300), []);

  useEffect(() => {
    handleSearch(undefined);
    defaultOptionsFunction();
    getSelectOptions();
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [filter?.sectionId]);

  const defaultOptionsFunction = () => {
    if (getParams()[name] && getParams()[id]) {
      setSelectedOption({
        _id: getParams()[id],
        [field]: getParams()[name],
      });
    } else if (defaultOptions) {
      setSelectedOption(defaultOptions);
      setFilter((prev: any) => {
        return {
          ...prev,
          [id]: defaultOptions._id,
          [name]:
            defaultOptions[field] +
            " " +
            (defaultOptions.lastName ? defaultOptions.lastName : ""),
        };
      });
    }
  };

  const getSelectOptions = async () => {
    if (!searchable) {
      if (!staticOptions.length) {
        if (dataUrl) {
          try {
            const response = await client[method](dataUrl, optional);
            if (response.data) setOptionsSelect(response.data);
          } catch (e: any) {
            if (e.response.data.code === 401) {
              localStorage.clear();
              navigate("/login");
            }
          }
        }
      } else {
        setOptionsSelect(staticOptions);
      }
    }
  };

  const clearIconClick = async (event?: React.MouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();
    setFilter((prev: any) => {
      return {
        ...prev,
        [name]: null,
        [id]: null,
      };
    });
    setSelectedOption(undefined);
  };

  useEffect(() => {
    if (staticOptions.length) {
      setOptionsSelect(staticOptions);
    }
  }, [staticOptions]);

  useEffect(() => {
    if (defaultOptions && name === "yearName") {
      setSelectedOption(defaultOptions);
      setFilter((prev: any) => ({ ...prev, year: defaultOptions._id }));
    }
  }, [defaultOptions]);

  return (
    <FilterSelectStyled style={style} className={`${className}`}>
      {/* {label && <div className={"select-label"}>{label}</div>} */}
      <div className={`main`}>
        <div ref={outsideOfSelectRef} className={"drop-down-container"}>
          <div
            className={
              "drop-down-header d-flex align-items-center justify-content-between"
            }
            onClick={toggling}
          >
            {(
              <div className="d-flex align-items-center justify-content-between w-100">
                {selectedOption ? (
                  <>
                    <p className="mb-0 list-name">
                      {get(selectedOption, field) +
                        " " +
                        (selectedOption.lastName
                          ? selectedOption.lastName
                          : "")}
                    </p>
                    {isClearable && (
                      <div
                        className="ms-2 delete-filter"
                        onClick={clearIconClick}
                      >
                        &#10005;
                      </div>
                    )}
                  </>
                ) : (
                  <>{label}</>
                )}
              </div>
            ) || `${optionAllView ? { label } : "Tanlanmagan"}`}

            <SelectArrow />
          </div>
          {isOpen && (
            <div className={"drop-down-list-container"}>
              {searchable && (
                <div className="search-input-content">
                  <input
                    onChange={debounceSearch}
                    className={"search-option"}
                    type="text"
                    placeholder="Izlash"
                  />
                </div>
              )}

              <div className={"drop-down-list"}>
                {optionsSelect?.map((item: any) => {
                  return (
                    <div
                      key={item._id}
                      onClick={() => handleChange(item)}
                      className="list-wrapper"
                    >
                      <div
                        className={`list-item ${
                          selectedOption?._id === item._id ? "active" : ""
                        }`}
                      >
                        {/* {item[field] +
                          " " +
                          (item.lastName ? item.lastName : "")} */}
                        {get(item, field) +
                          " " +
                          (item.lastName ? item.lastName : "")}
                      </div>
                      {selectedOption?._id === item._id ? (
                        <CheckedIcon className="checkedIcon" />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                {renderChildren()}
              </div>
            </div>
          )}
        </div>
      </div>
    </FilterSelectStyled>
  );
};

export default memo(FilterSelect);

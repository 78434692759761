import { LoginStyle } from "./Login.style";
import Logo from "../../../assets/Logo.png";
import PhoneInput from "../../../components/form/PhoneInput/PhoneInput";
import { phoneNumberPattern } from "../../../components/form/PhoneInput/PhoneInput.constants";
import { CustomButton } from "../../../components/style/style";
import SmsIcon from "../../../assets/SmsIcon";
import { Input } from "../../../components";
import SmsVectorIcon from "../../../assets/SmsVectorIcon";
import { useContext } from "react";
import { LoginContext } from "../context";
import { REQUEST_STATUS } from "../../../hooks";

const Login = () => {
  const {
    state: {
      otp,
      formStore,
      loginState: { loginData, loginStatus },
    },
    actions: { login },
  } = useContext(LoginContext);

  const {
    register,
    formState: { errors },
  } = formStore;

  return (
    <LoginStyle>
      <div className="form-wrapper">
        <form className="form" onSubmit={login}>
          <h2 className="page-title title">ROVI ADMINGA XUSH KELIBSIZ</h2>
          <div className="d-flex align-items-cente justify-content-between mb-4">
            <PhoneInput
              placeholder="+998 -- --- -- --"
              params={{
                ...register("phoneNumber", {
                  required: {
                    value: true,
                    message: "Majburiy qator",
                  },
                  minLength: {
                    value: 9,
                    message: "",
                  },
                  pattern: {
                    value: phoneNumberPattern,
                    message: "",
                  },
                }),
              }}
              error={errors.phoneNumber}
              className="input phoneNumberInput"
            />
            {!otp && (
              <CustomButton className="ms-2 login-btn">
                <SmsIcon />
              </CustomButton>
            )}
          </div>
          {otp && (
            <div className="d-flex align-items-center justify-content-between">
              <Input
                params={{
                  ...register("otp", {
                    required: true,
                  }),
                  type: "number",
                }}
                placeholder="SMS kodni kiriting"
                error={errors.otp}
                className="code-input"
              />
              <CustomButton type="submit" className="ms-2 login-btn">
                <SmsVectorIcon />
              </CustomButton>
            </div>
          )}
        </form>
      </div>
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
    </LoginStyle>
  );
};

export default Login;

const CommentIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50034 11C10.091 11 13.0003 8.5375 13.0003 5.5C13.0003 2.4625 10.091 0 6.50034 0C2.90972 0 0.000342014 2.4625 0.000342014 5.5C0.000342014 6.70625 0.459717 7.82187 1.23784 8.73125C1.12847 9.025 0.965967 9.28438 0.794092 9.50313C0.644092 9.69688 0.490967 9.84688 0.378467 9.95C0.322217 10 0.275342 10.0406 0.244092 10.0656C0.228467 10.0781 0.215967 10.0875 0.209717 10.0906L0.203467 10.0969C0.031592 10.225 -0.043408 10.45 0.025342 10.6531C0.094092 10.8562 0.284717 11 0.500342 11C1.18159 11 1.86909 10.825 2.44097 10.6094C2.72847 10.5 2.99722 10.3781 3.23159 10.2531C4.19097 10.7281 5.30659 11 6.50034 11ZM14.0003 5.5C14.0003 9.00937 10.9035 11.6531 7.23472 11.9688C7.99409 14.2937 10.5128 16 13.5003 16C14.6941 16 15.8097 15.7281 16.7722 15.2531C17.0066 15.3781 17.2722 15.5 17.5597 15.6094C18.1316 15.825 18.8191 16 19.5003 16C19.716 16 19.9097 15.8594 19.9753 15.6531C20.041 15.4469 19.9691 15.2219 19.7941 15.0938L19.7878 15.0875C19.7816 15.0812 19.7691 15.075 19.7535 15.0625C19.7222 15.0375 19.6753 15 19.6191 14.9469C19.5066 14.8438 19.3535 14.6938 19.2035 14.5C19.0316 14.2812 18.8691 14.0187 18.7597 13.7281C19.5378 12.8219 19.9972 11.7063 19.9972 10.4969C19.9972 7.59687 17.3441 5.21875 13.9785 5.0125C13.991 5.17188 13.9972 5.33437 13.9972 5.49687L14.0003 5.5Z"
        fill="white"
      />
    </svg>
  );
};

export default CommentIcon;

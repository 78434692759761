import get from "lodash.get";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CommonContext } from "../../../context";
import { REQUEST_STATUS, useRequest } from "../../../hooks";
import browserStorage from "../../../services/storage/browserStorage";

export const useCommon = () => {
  const [otp, setOtp] = useState(false);
  const navigate = useNavigate();
  const formStore = useForm();
  const { handleSubmit } = formStore;
  const {
    state: {},
    actions: { getRole },
  } = useContext(CommonContext);

  const [loginClient, loginData, loginStatus] = useRequest();

  const login = handleSubmit(async (data: any) => {
    const res = await loginClient.post(
      data.otp ? "sign-verify" : "sign-in",
      data
    );
    if (res?.statusCode === 200) {
      !data.otp && setOtp(true);
    }
  });

  useEffect(() => {
    if (
      loginStatus === REQUEST_STATUS.success &&
      get(loginData, "data.token")
    ) {
      browserStorage.set("token", get(loginData, "data.token"));
      browserStorage.set("admin", get(loginData, "data"));
      getRole(get(loginData, "data.roleId", ""));
      navigate("/sections");
    }
  }, [loginStatus]);

  return {
    state: {
      otp,
      formStore,
      loginState: {
        loginData,
        loginStatus,
      },
    },
    actions: {
      login,
    },
  };
};

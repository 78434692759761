import { Menu } from "react-pro-sidebar";
import styled from "styled-components";

export const SidebarStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100% !important;
  z-index: 1001;
  .pro-sidebar-layout {
    background-color: ${({ theme }) => theme.black.main};
  }
  .pro-sidebar-header {
    border-bottom: none !important;
  }
  .pro-sidebar-footer {
    border-top: none !important;
  }
  .sidebar-header {
    padding: 32px;
    img {
      width: 42px;
      height: 80px;
    }
  }
  .sidebar-footer {
    padding: 24px;
    .logout {
      font-weight: 500;
      font-size: 14px;
      border-radius: 16px;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.rgba.light};
      color: ${({ theme }) => theme.white.main};
      border: none;
      width: 100%;
      cursor: pointer;
      svg {
        margin-left: 8px;
      }
    }
  }
  .pro-sidebar-inner {
    transition: all 0.3s linear !important;
    background: #fff !important;
    min-width: 100%;
    width: 100%;
    .pro-sidebar-layout {
      overflow: hidden !important;
      .pro-sidebar-content {
        overflow-y: auto !important;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          /* background: ${({ theme }) => theme.white.main};
          border-radius: 5px; */
        }

        &::-webkit-scrollbar-thumb:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .active {
    background-color: ${({ theme }) => theme.rgba.white};
  }
`;

export const MenuStyled = styled(Menu)`
  padding: 0 !important;
  ul {
    .pro-menu-item {
      .pro-inner-item {
        &:hover {
          background-color: ${({ theme }) => theme.rgba.white};
        }
        .pro-icon-wrapper {
          background-color: transparent !important;
        }
        .pro-item-content {
          color: ${({ theme }) => theme.white.main};
        }
      }
    }
  }
`;

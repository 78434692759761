import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import { IImage } from "./Image.types";

const Image = ({
  url,
  alt = "image",
  className,
  isLoader = true,
  isSmall,
}: IImage) => {
  const [loader, setLoader] = useState(false);
  const [imageSrc, setImageSrc] = useState<any>({});
  const authToken = `Bearer ${localStorage.getItem("token")}`;
  const imageUrl = process.env.REACT_APP_UPLOAD_URL + "uploads/" + url;

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const response = await fetch(imageUrl, {
        headers: {
          Authorization: `${authToken}`,
        },
      });
      const blob = await response.blob();
      setImageSrc((prev: any) => ({
        ...prev,
        ["image" + url]: URL.createObjectURL(blob),
      }));
      setLoader(false);
    };
    fetchData();
  }, [authToken, imageUrl]);

  if (isLoader && loader)
    return (
      <CircularProgress
        size={isSmall ? "20px" : "40px"}
        className="image-circular-progress"
      />
    );

  return <img src={imageSrc["image" + url]} alt={alt} className={className} />;
};

export default Image;

import { lazy } from "react";
import { IRoute } from "./Routes.types";

const SectionList = lazy(() => import("../../pages/sections/list/container"));
const CategoryList = lazy(
  () => import("../../pages/sections/edit/container/category/container")
);
const CourseList = lazy(
  () => import("../../pages/sections/edit/container/course/container")
);
const AddEditCourse = lazy(
  () =>
    import(
      "../../pages/sections/edit/container/course/components/AddEditCourse"
    )
);
const Subcourses = lazy(
  () => import("../../pages/sections/edit/container/subcourse/container")
);
const Demos = lazy(
  () => import("../../pages/sections/edit/container/demo/container")
);
const Tasks = lazy(() => import("../../pages/tasks/container"));
const Comments = lazy(() => import("../../pages/comments/container"));
const Users = lazy(() => import("../../pages/users/container"));
const Roles = lazy(() => import("../../pages/roles/container"));
const AddEditRole = lazy(
  () => import("../../pages/roles/components/AddEditRole")
);
const Moderators = lazy(() => import("../../pages/moderators/container"));
const News = lazy(() => import("../../pages/news/container"));
const Ads = lazy(() => import("../../pages/ads/container"));
const SmsTemplates = lazy(() => import("../../pages/smsTemplates/container"));
const QrCodes = lazy(() => import("../../pages/qrCodes/container"));
const About = lazy(() => import("../../pages/about/container"));
const Report = lazy(() => import("../../pages/report/container"));

export const routes: IRoute[] = [
  {
    key: "notFound",
    name: "sections",
    isPrivate: true,
    path: "/sections",
    element: <SectionList />,
  },
  {
    key: "notFound",
    name: "categories",
    isPrivate: true,
    path: "/categories/:sectionId",
    element: <CategoryList />,
  },
  {
    key: "notFound",
    name: "courses",
    isPrivate: true,
    path: "/courses/:sectionId",
    element: <CourseList />,
  },
  {
    key: "notFound",
    name: "courses",
    isPrivate: true,
    path: "/courses/:categoryId/:sectionId",
    element: <CourseList />,
  },
  {
    key: "createCourse",
    name: "courses",
    isPrivate: true,
    path: "/courses/:categoryId/:sectionId/add",
    element: <AddEditCourse />,
  },
  {
    key: "updateCourse",
    name: "courses",
    isPrivate: true,
    path: "/courses/:categoryId/:sectionId/edit/:courseId",
    element: <AddEditCourse />,
  },
  {
    key: "createCourse",
    name: "courses",
    isPrivate: true,
    path: "/courses/:sectionId/add",
    element: <AddEditCourse />,
  },
  {
    key: "updateCourse",
    name: "courses",
    isPrivate: true,
    path: "/courses/:sectionId/edit/:courseId",
    element: <AddEditCourse />,
  },
  {
    key: "notFound",
    name: "subcourses",
    isPrivate: true,
    path: "/subcourses/:courseId",
    element: <Subcourses />,
  },
  {
    key: "notFound",
    name: "subcourses",
    isPrivate: true,
    path: "/subcourses/:courseId/:categoryId",
    element: <Subcourses />,
  },
  {
    key: "notFound",
    name: "demos",
    isPrivate: true,
    path: "/demos/:courseId/:sectionId",
    element: <Demos />,
  },
  {
    key: "notFound",
    name: "demos",
    isPrivate: true,
    path: "/demos/:courseId/:categoryId/:sectionId",
    element: <Demos />,
  },
  {
    key: "getTask",
    name: "tasks",
    isPrivate: true,
    path: "/tasks",
    element: <Tasks />,
  },
  {
    key: "notFound",
    name: "comments",
    isPrivate: true,
    path: "/comments",
    element: <Comments />,
  },
  {
    key: "notFound",
    name: "users",
    isPrivate: true,
    path: "/users",
    element: <Users />,
  },
  {
    key: "getRole",
    name: "roles",
    isPrivate: true,
    path: "/roles",
    element: <Roles />,
  },
  {
    key: "createRole",
    name: "roles",
    isPrivate: true,
    path: "/roles/add",
    element: <AddEditRole />,
  },
  {
    key: "updateRole",
    name: "roles",
    isPrivate: true,
    path: "/roles/edit/:id",
    element: <AddEditRole />,
  },
  {
    key: "getEmployee",
    name: "moderators",
    isPrivate: true,
    path: "/moderators",
    element: <Moderators />,
  },
  {
    key: "getNews",
    name: "news",
    isPrivate: true,
    path: "/news",
    element: <News />,
  },
  {
    key: "notFound",
    name: "ads",
    isPrivate: true,
    path: "/ads",
    element: <Ads />,
  },
  {
    key: "getSmsT",
    name: "sms-templates",
    isPrivate: true,
    path: "/sms-templates",
    element: <SmsTemplates />,
  },
  {
    key: "notFound",
    name: "qr-codes",
    isPrivate: true,
    path: "/qr-codes",
    element: <QrCodes />,
  },
  {
    key: "notFound",
    name: "about",
    isPrivate: true,
    path: "/about",
    element: <About />,
  },
  {
    key: "notFound",
    name: "report",
    isPrivate: true,
    path: "/report",
    element: <Report />,
  },
];

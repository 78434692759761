import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRequest } from "../hooks";

export const useCommon = () => {
  const admin: any = localStorage.getItem("admin");
  const roleId: any = JSON.parse(admin)?.roleId;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [roleClient, roleData, roleStatus, roleError] = useRequest();

  const getRole = (id?: string) => {
    roleClient.get(`role/${id || roleId}`);
  };

  useEffect(() => {
    getRole();
  }, [pathname]);

  const logOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  return {
    state: {
      roleState: {
        roleData,
        roleStatus,
        roleError,
      },
    },
    actions: {
      getRole,
      logOut,
    },
  };
};

import { ISideItemsT } from "./Sidebar.types";
import SectionIcon from "./assets/SectionIcon";
import TaskIcon from "./assets/TaskIcon";
import CommentIcon from "./assets/CommentIcon";
import UserIcon from "./assets/UserIcon";
import QRIcon from "./assets/QRIcon";
import ModeratorIcon from "./assets/ModeratorIcon";
import AboutIcon from "./assets/AboutIcon";
import NewsIcon from "./assets/NewsIcon";
import SmsTemplateIcon from "./assets/SmsTemplateIcon";
import AdIcon from "./assets/AdIcon";

export const SidebarList: ISideItemsT[] = [
  {
    id: "notFound",
    path: "/sections",
    icon: <SectionIcon />,
    title: "Bo'limlar",
    insidePaths: ["/sections"],
  },
  {
    id: "getTask",
    path: "/tasks",
    icon: <TaskIcon />,
    title: "Vazifalar",
    insidePaths: ["/tasks"],
  },
  {
    id: "notFound",
    path: "/comments",
    icon: <CommentIcon />,
    title: "Kommentariyalar",
    insidePaths: ["/comments"],
  },
  {
    id: "notFound",
    path: "/users",
    icon: <UserIcon />,
    title: "Foydalanuvchilar",
    insidePaths: ["/users"],
  },
  {
    id: "notFound",
    path: "/qr-codes",
    icon: <QRIcon />,
    title: "QR kod",
    insidePaths: ["/qr"],
  },
  {
    id: "getRole",
    path: "/roles",
    icon: <SectionIcon />,
    title: "Rollar",
    insidePaths: ["/roles"],
  },
  {
    id: "getEmployee",
    path: "/moderators",
    icon: <ModeratorIcon />,
    title: "Moderatorlar",
    insidePaths: ["/moderators"],
  },
  {
    id: "notFound",
    path: "/about",
    icon: <AboutIcon />,
    title: "Rovi talk haqida",
    insidePaths: ["/about"],
  },
  {
    id: "getNews",
    path: "/news",
    icon: <NewsIcon />,
    title: "Yangiliklar",
    insidePaths: ["/news"],
  },
  {
    id: "getSmsT",
    path: "/sms-templates",
    icon: <SmsTemplateIcon />,
    title: "Sms shablonlar",
    insidePaths: ["/sms-templates"],
  },
  {
    id: "notFound",
    path: "/ads",
    icon: <AdIcon />,
    title: "Reklama",
    insidePaths: ["/ads"],
  },
  {
    id: "notFound",
    path: "/report",
    icon: <TaskIcon />,
    title: "Hisobotlar",
    insidePaths: ["/report"],
  },
];

import React, { FC } from "react";
import Error from "../Error/Error";
import Label from "../Label/Label";
import { MaskInputStyled } from "./PhoneInput.style";
import { IPhoneInputProps } from "./PhoneInput.types";
import MaskInput from "react-input-mask";

const PhoneInput: FC<IPhoneInputProps> = ({
  defaultValue,
  error,
  params,
  placeholder = "",
  name = "phoneNumber",
  label = "",
  className = "",
}) => {
  return (
    <MaskInputStyled className={className}>
      {!!label && <Label label={label} />}
      <MaskInput
        defaultValue={defaultValue}
        {...params}
        className={`reactInputMask  ${!!error && "error"} `}
        mask="+\9\98 99 999 99 99"
        name={name}
        maskChar="-"
        placeholder={placeholder}
      />
      {/* {!!error && <Error message={error.message} />} */}
    </MaskInputStyled>
  );
};

export default PhoneInput;

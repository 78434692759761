const SelectArrow = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={12}
      height={12}
      {...props}
    >
      <path
        fill="none"
        stroke="#7E8299"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m2 5 6 6 6-6"
      />
    </svg>
  );
};

export default SelectArrow;

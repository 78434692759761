const TaskIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196 19.021 0 18.55 0 18V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H9.175C9.44167 0 9.696 0.0500001 9.938 0.15C10.1793 0.25 10.3917 0.391667 10.575 0.575L15.425 5.425C15.6083 5.60833 15.75 5.82067 15.85 6.062C15.95 6.304 16 6.55833 16 6.825V18C16 18.55 15.8043 19.021 15.413 19.413C15.021 19.8043 14.55 20 14 20H2ZM9 6C9 6.28333 9.096 6.52067 9.288 6.712C9.47933 6.904 9.71667 7 10 7H14L9 2V6ZM6.925 13.125L5.525 11.725C5.425 11.625 5.31667 11.55 5.2 11.5C5.08333 11.45 4.96267 11.425 4.838 11.425C4.71267 11.425 4.58767 11.45 4.463 11.5C4.33767 11.55 4.225 11.625 4.125 11.725C3.925 11.925 3.825 12.1627 3.825 12.438C3.825 12.7127 3.925 12.95 4.125 13.15L6.25 15.3C6.35 15.4 6.45833 15.4707 6.575 15.512C6.69167 15.554 6.81667 15.575 6.95 15.575C7.08333 15.575 7.20833 15.554 7.325 15.512C7.44167 15.4707 7.55 15.4 7.65 15.3L11.875 11.075C12.075 10.875 12.175 10.6333 12.175 10.35C12.175 10.0667 12.075 9.825 11.875 9.625C11.675 9.425 11.4333 9.325 11.15 9.325C10.8667 9.325 10.625 9.425 10.425 9.625L6.925 13.125Z"
        fill="white"
      />
    </svg>
  );
};

export default TaskIcon;

import { FC } from "react";
import { CheckboxStyled } from "./Checkbox.style";
import { CheckboxPropsType } from "./Checkbox.types";
import { Checkbox as MUICheckbox } from "@mui/material";
import { Controller } from "react-hook-form";

const Checkbox: FC<CheckboxPropsType> = ({
  label = "",
  control,
  name,
  defaultChecked = false,
  checked,
  onChange,
}) => {
  return (
    <CheckboxStyled
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultChecked || false}
          render={({ field }: any) => {
            return (
              <MUICheckbox
                defaultChecked={defaultChecked}
                checked={checked}
                onClick={(e: any) => onChange && onChange(e.target.checked)}
                {...field}
                sx={{
                  color: "#999",
                  "&.Mui-checked": {
                    color: "#70B17C",
                  },
                }}
              />
            );
          }}
        />
      }
      onChange={(e: any) => console.log(e)}
      label={label}
    />
  );
};

export default Checkbox;

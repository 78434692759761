const NewsIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1.5C13 1.10218 13.158 0.720644 13.4393 0.43934C13.7206 0.158035 14.1022 0 14.5 0C14.8978 0 15.2794 0.158035 15.5607 0.43934C15.842 0.720644 16 1.10218 16 1.5V12.5C16 12.8978 15.842 13.2794 15.5607 13.5607C15.2794 13.842 14.8978 14 14.5 14C14.1022 14 13.7206 13.842 13.4393 13.5607C13.158 13.2794 13 12.8978 13 12.5V1.5ZM12 2.224C9.933 3.174 7.461 3.705 5 3.88V10.117C5.36324 10.1375 5.72599 10.1658 6.088 10.202C8.141 10.406 10.126 10.87 12 11.762V2.224ZM4 10.065V3.934C3.32 3.961 2.601 3.977 1.992 3.987C1.46234 3.99434 0.956756 4.20944 0.584163 4.58596C0.21157 4.96248 0.00178478 5.47029 0 6L0 8C0 9.106 0.896 9.996 1.994 10.009C2.15934 10.0111 2.32468 10.0137 2.49 10.017C2.9935 10.0271 3.49687 10.0431 4 10.065ZM5.39 11.146C5.675 11.167 5.959 11.193 6.24 11.224L6.493 12.914C6.52053 13.0586 6.51579 13.2075 6.47911 13.3501C6.44243 13.4927 6.37473 13.6254 6.28083 13.7388C6.18692 13.8522 6.06914 13.9435 5.9359 14.0061C5.80265 14.0687 5.65722 14.1011 5.51 14.101H4.962C4.76762 14.101 4.57746 14.0443 4.41478 13.9379C4.2521 13.8316 4.12395 13.6801 4.046 13.502L2.732 11.022C3.29625 11.0361 3.8603 11.0574 4.424 11.086C4.751 11.103 5.074 11.123 5.39 11.146Z"
        fill="white"
      />
    </svg>
  );
};

export default NewsIcon;
